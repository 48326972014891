<template>
<div>
  <div class="alert-info" v-if="$debug.isOn">
    profileOptions: {{ profileOptions }}
  </div>
  <div v-if="id">
    <h2 class="mt-3">Edit Profile</h2>
  </div>
  <div class="text-center" v-else>
    <h2 class="mt-3">Basic Info</h2>
    <b-alert :show="profileOptions.instructions">
      Welcome to {{ $app.productName }}!<br/>
      We haven't had the pleasure to meet yet.<br/>
      To get started please fill up your profile.
    </b-alert>
    <video playsinline autoplay muted loop width="50%" v-if="profileOptions.topAnimation">
      <source src="https://global-uploads.webflow.com/5bcb46130508ef456a7b2930/5d06154c6a9360b7a49538b6_drawkit-grape-pack-illustration-8-transcode.mp4" type="video/mp4">
    </video>
  </div>
  <label class="mt-2">Name *
    <privacy-icon icon="id-card" tip="This info is shared with people participating to the event"/>
  </label>
  <b-form-input v-model="name"/>
  <div v-if="profileOptions.age">
    <label class="mt-2">Age *
      <privacy-icon icon="id-card" tip="This info is shared with people participating to the event"/>
    </label>
    <b-form-input v-model="age"/>
  </div>
  <div class="mt-3" v-if="profileOptions.genderAndInterest">
    <b-form-group>
      <label>Gender *
        <privacy-icon icon="lock" tip="This info is not shared with others, only used by the matching algorithm"/>
      </label>
      <b-form-radio-group>
        <b-form-radio v-model="gender" value="m">Male</b-form-radio>
        <b-form-radio v-model="gender" value="f">Female</b-form-radio>
        <b-form-radio v-model="gender" value="nb">Non-Binary</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <b-form-group>
      <label>Interested to meet *
        <privacy-icon icon="lock" tip="This info is not shared with others, only used by the matching algorithm"/>
      </label>
      <b-form-radio-group>
      <b-form-radio value="m" v-model="interest">Male</b-form-radio>
      <b-form-radio value="f" v-model="interest">Female</b-form-radio>
      <b-form-radio value="b" v-model="interest">Both</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
  <div class="mt-3" v-if="profileOptions.location">
    <label>Location
      <privacy-icon icon="id-card" tip="This info is shared only after you meet with someone, and it's also used by the matching algorithm"/>
    </label>
    <b-form-input v-model="location"/>
  </div>
  <div class="mt-2" v-if="profileOptions.bio">
    <label>Bio
      <privacy-icon icon="id-card" tip="This info is shared with people participating to the event"/>
    </label>
    <b-form-input v-model="bio"/>
  </div>
  <div class="mt-3" v-if="profileOptions.pictureUpload">
    <label>{{ profileOptions.pictureUpload | pluralize('Picture') }} *
      <privacy-icon icon="id-card" tip="This info is shared with people participating to the event"/>
    </label>
    <p v-if="profileOptions.require2Pictures">Upload 2 pictures and press Start!</p>
    <p v-else-if="profileOptions.upload2Pictures">Upload 1 or 2 pictures and get ready!</p>
    <div class="d-flex d-flex-inline">
      <div>
        <div v-if="picture0">
          <img class="picture form-control" :src="makeURL(picture0)"/><br/>
          <button class="btn btn-secondary mt-1" @click="$refs.pic0input.click();">Replace</button>
        </div>
        <div class="picture-button form-control d-flex justify-content-center align-items-center" @click="$refs.pic0input.click();" v-else>
          <font-awesome-icon icon="images" :style="{ color: 'darkgrey' }" size="4x"/>
        </div>
      </div>
      <div class="ml-2" v-if="profileOptions.pictureUpload > 1">
        <div v-if="picture1">
          <img class="picture form-control" :src="makeURL(picture1)"/><br/>
          <button class="btn btn-secondary mt-1" @click="$refs.pic1input.click();">Replace</button>
        </div>
        <div class="picture-button form-control d-flex justify-content-center align-items-center" @click="$refs.pic1input.click();" v-else>
          <font-awesome-icon icon="images" :style="{ color: 'darkgrey' }" size="4x"/>
        </div>
      </div>
    </div>
    <div style="display:none;">
      <input ref="pic0input" type="file" accept="image/*" @change="picture0Change"/>
      <input ref="pic1input" type="file" accept="image/*" @change="picture1Change"/>
    </div>
  </div>
  <p class="mt-3" v-if="uploadInProgress && profileOptions.pictureUpload">Please wait, uploading pictures...</p>
  <div v-if="profileOptions.contactInfo" class="mt-3">
    <label>Email address
      <privacy-icon icon="lock" tip="This info is not shared with others only used for support"/>
    </label> 
    <b-form-input type="email" v-model="email"/>
    <label class="mt-2">Phone number
      <privacy-icon icon="lock" tip="This info is not shared with others only used for support"/>
    </label>
    <b-form-input type="tel" v-model="phonenumber"/>
  </div>
  <div v-if="profileOptions.matchContactInfo" class="mt-3">
    <label>Contact *
      <privacy-icon icon="thumbs-up" tip="This info is only shared with people you liked"/>
    </label>
    <div class="d-flex align-items-center">
      <b-form-group>
        <b-form-radio v-model="matchContactType" value="instagram">Instagram</b-form-radio>
        <b-form-radio v-model="matchContactType" value="snap">Snap</b-form-radio>
        <b-form-radio v-model="matchContactType" value="phonenumber">Phone</b-form-radio>
      </b-form-group>
      <div class="ml-4" v-if="matchContactType">
        <div v-if="matchContactType == 'phonenumber'">
          <label for="matchContactInfo">Phone Number:</label>
          <b-form-input  type="tel" style="width:250pt" id="matchContactInfo" placeholder='' v-model="matchContactInfo"/>
        </div>
        <div v-else>
          <label for="matchContactInfo">{{ matchContactType | capitalize }} Handle:</label>
          <b-form-input  type="text" style="width:250pt" id="matchContactInfo" placeholder='' v-model="matchContactInfo"/>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3" v-if="profileOptions.advancedMatch">
    <label>Matching preferences
      <privacy-icon icon="lock" tip="This info is not shared with others, only used by the matching algorithm"/>
    </label>
    <b-form-group label="I want to talk to people">
      <b-form-radio v-model="matchPrefsLocation" value="national">anywhere in the US</b-form-radio>
      <b-form-radio v-model="matchPrefsLocation" value="local">in my region only</b-form-radio>
    </b-form-group>
    <b-form-group label="I want to talk to people to">
      <b-form-radio v-model="matchPrefsTalkTo" value="dates">go on dates</b-form-radio>
      <b-form-radio v-model="matchPrefsTalkTo" value="meet">meet new people, not necessarily go on a date</b-form-radio>
    </b-form-group>
    <b-form-group label="What about age difference">
      <b-form-radio v-model="matchPrefsAge" value="5years">around my age (+/- 5 years)</b-form-radio>
      <b-form-radio v-model="matchPrefsAge" value="none">I don't mind</b-form-radio>
    </b-form-group>
  </div>
  <div class="mt-3">
    <button 
      class="btn btn-primary" 
      :disabled="!canStart"
      @click.prevent="start">{{ id ? "Save" : "Start" }}</button>
    <button
      v-if="$debug.isOn"
      class="ml-3 btn btn-warning"
      @click.prevent="start">Force {{ id ? "Save" : "Start" }} (Skips Missing Required Fields) </button>
    <b-alert :show="savedDisplay" variant="light" fade class="p-0 d-inline">
      Profile saved...
    </b-alert>
  </div>
</div>
</template>

<script>
import { storage, serverTimestamp } from '../services/db';
import { getLog } from "../services/log";
let log = getLog('guest');

import { cleanFilename, removeNull } from '../services/utils';
import { readAndCompressImage } from 'browser-image-resizer';
import { privacyIcon } from "../components/privacyicon";

const compressConfig = {
  quality: 0.9,
  maxWidth: 1024,
  maxHeight: 1024,
  autoRotate: true,
  debug: true
};

export default {
  name: "profile",
  components: {
    privacyIcon,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      // Profile options
      datingProfileOptions: {
        "instructions": true,
        "age": true,
        "location": true,
        "bio": true,
        "pictureUpload": 2,
        "require2Pictures" : true,
        "genderAndInterest" : true,
        "contactInfo": false,
        "matchContactInfo": true,
        "advancedMatch": false,
      },
      proProfileOptions: {
        "topAnimation": true,
        "instructions": false,
        "pictureUpload": 1,
      },
      callProfileOptions: {        
      },
      stageProfileOptions: {
        "pictureUpload": 1,
      },
      profileOptions: {},

      id: null, // null means new user

      name: "",
      age: null,
      location: "",
      bio: "",

      phonenumber: null,
      email: null,

      matchContactType: null,
      matchContactInfo: null,
      matchPrefsLocation: null,
      matchPrefsTalkTo: null,
      matchPrefsAge: null,

      gender: null,
      interest: null,

      picture0: null,
      picture1: null,

      uploadInProgress: false,
      savedDisplay: false,
    }
  },
  computed: {
    canStart() {
      return (
        (!this.profileOptions.contactInfo || (this.phonenumber && this.email)) 
        && (!this.profileOptions.matchContactInfo || this.matchContactInfo)
        && (!this.profileOptions.pictureUpload || (this.profileOptions.require2Pictures ? (this.picture0 && this.picture1) : (this.picture0 || this.picture1))) 
        && (!this.profileOptions.genderAndInterest || (this.gender && this.interest))
        && (!this.profileOptions.age || this.age)
        && !this.uploadInProgress && this.name 
      );
    }
  },
  mounted() {
    this.profileOptions = this.callProfileOptions;
    let profileOptions = (this.event && this.event.profileOptions) || this.$route.query.profileOptions;
    if (profileOptions) {
      log.log("loading profile options from event or query params", profileOptions);
      if (typeof profileOptions == "string") {
        this.profileOptions = {
          dating: this.datingProfileOptions,
          pro: this.proProfileOptions,
          call: this.callProfileOptions,
          stage: this.stageProfileOptions,
        }[profileOptions];
      } else
        this.profileOptions = profileOptions;
    } else {
      log.log("no profile options");
    }
    if (this.$route.query.pf) {
      log.log("Prefilling with query params");
      this.prefill(this.$route.query);
    } else if (this.$parent.$parent.user) {
      log.log("Prefilling with user");
      this.prefill(this.$parent.$parent.user);
    }
  },
  methods: {
    prefill(pf) {
      let fields = ["id", "name", "age", "phonenumber", "email", 
        "interest", "gender", "picture0", "picture1",
        "matchContactType", "matchContactInfo"];
      fields.forEach(f => {
        if (pf[f])
          this[f] = pf[f];
      });
      if (pf.start && this.canStart)
        this.start();
    },
    // Form
    async picture0Change(e) {
      log.log("picture0Change");
      let file = (e.target.files || e.dataTransfer.files)[0];
      this.picture0 = await readAndCompressImage(file, compressConfig);
      log.log("picture0Change compression complete");
    },
    async picture1Change(e) {
      log.log("picture1Change");
      let file = (e.target.files || e.dataTransfer.files)[0];
      this.picture1 = await readAndCompressImage(file, compressConfig);
      log.log("picture1Change compression complete");
    },
    async upload(images) {
      let ref = storage.ref();
      log.log("upload images", images);
      let urlsPromises = images.map((image) => new Promise((resolve) => { 
        if (!image)
          return resolve(null);
        let imageName = `${cleanFilename(this.name)}_${Math.floor(Math.random() * 1000000).toString()}.jpg`;
        try {
          ref.child(`images/${imageName}`).put(image).then(snapshot => {
            log.log("uploaded file=", imageName);
            snapshot.ref.getDownloadURL().then((downloadURL) => {
              log.log(`downloadURL=${downloadURL}`);
              resolve(downloadURL);
            });
          });
        }
        catch (e) {
          this.uploadInProgress = false;
          log.error("upload error", e);
        }
      }));
      return new Promise((resolve) => {
        Promise.all(urlsPromises).then((urls) => {
          log.log("upload urls=", urls);
          resolve(urls);
        });
      });
    },
    async start() {
      this.uploadInProgress = true;
      let nopic = "https://ui-avatars.com/api/?size=512&name=" + this.name;
      let uploads = [];
      if (!this.picture0)
        this.picture0 = nopic;
      if (typeof this.picture0 != 'string')
        uploads.push(this.picture0);
      else 
        uploads.push(null);
      if (!this.picture1)
        this.picture1 = nopic;
      if (typeof this.picture1 != 'string')
        uploads.push(this.picture1);
      else 
        uploads.push(null);
      let urls = await this.upload(uploads);
      if (urls[0]) this.picture0 = urls[0];
      if (urls[1]) this.picture1 = urls[1];
      let user = {
        id: this.id,
        name: this.name,
        age: this.age,
        phonenumber: this.phonenumber,
        email: this.email,
        matchContactType: this.matchContactType,
        matchContactInfo: this.matchContactInfo,
        picture0: this.picture0,
        picture1: this.picture1,
        gender: this.gender,
        interest: this.interest,
        bio: this.bio,
        location: this.location,
      }
      removeNull(user);
      if (!this.id)
        user.createdAt = serverTimestamp();
      this.uploadInProgress = false;
      this.savedDisplay = true;
      setTimeout(() => { this.savedDisplay = false }, 1000);
      this.$emit("complete", user);
    },
    makeURL(picture) {
      if (typeof picture == 'string')
        return picture;
      return window.URL.createObjectURL(picture);
    }
  }
}
</script>

<style scoped>

label {
  font-weight: bold;
}

.picture-button {
  width: 180px;
  height: 180px;
  background-color: #F0F0F0;
}

.picture {
  width: 180px;
  height: 180px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #000;
  object-fit: contain;  
}

</style>