<template>
<div>
  <div v-if="ToUBye">
    Please allow 7 days to delete. Thank you.
  </div>
  <div v-else>
    <h1>{{$app.productName}} Terms and Conditions</h1>
    <ol>
    <li>By registering for a {{$app.eventName}} (“Event”), you agree to be bound by this General Release and Consent Agreement (“Agreement”) and agree that {{$app.companyName}} (“Host”) and its affiliates, subsidiaries and their respective members, directors, officers, employees, and agents are not liable for any damages, direct or indirect, incidental and/or consequential, arising out of attendance at Event, including, without limitation, damages arising out of communicating and/or meeting with other participants from Event. Such damages include, without limitation, physical damages, bodily injury and/or emotional distress.</li>
    <li>You must be at least eighteen years old to participate in the Event.</li>
    <li>The goal of the Host is to help single people meet one another in a safe and comfortable setting. Host does not screen participants or verify personal information, such as marital status, physical health, mental health, or any other aspects of their general background. It is up to you to clarify these issues to your own satisfaction. Host makes no representation or warranties as to the conduct of other participants.</li>
    <li>You hereby acknowledge and understand that by participating in the Event, your first name, age, and pictures will be provided to other participants. Your phone number and/or email will be released for further communications after the scheduled event you attend.</li>
    <li>Host may make audio recordings of the Event. Such recordings may be used by Host for any legal purpose including marketing and advertising. Your name and pictures will not be tied to any recordings outside of Host’s internal use. At no time will there be audio recordings of any offline “dates.” No person shall be entitled to any compensation in connection with the use of any audio recordings.</li>
    <li>Host does not guarantee that any participant will successfully match with any other participant or that participation will be successful or that participation will result in actual dates or introductions. You agree to release Host and assume the full responsibility and risk with respect to the outcome of any relationship that may or may not arise between you and other event participants.</li>
    </ol>
    <div v-if="!hideAgreement">
      <p>
        By pressing 'I agree', you acknowledge that you have read and understand this Agreement, and are executing it knowingly, voluntarily and free from any coercion or duress.
      </p>
      <button 
        class="mt-3 btn btn-primary"
        @click="sign(true)">I agree</button>&nbsp;
      <button 
        class="mt-3 btn btn-secondary"
        @click="sign(false)">I don't agree, please delete my account</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      ToUBye: false,
    }
  },
  props: {
    hideAgreement: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    sign(value) {
      if (!value)
        this.ToUBye = true;
      this.$emit("signToU", value)
    }
  }
}

</script>