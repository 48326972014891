<template>
<span class="inline">
  <font-awesome-icon :icon="icon" :style="{ color: 'grey' }" 
    :title="tip"
    class="ml-1 mt-1"
    @click="show = !show"/>
  <b-alert variant="info" :show="show" class="mb-0">
    {{ tip }}
  </b-alert>
</span>
</template>

<script>
export let privacyIcon = {
  name: "privacy-icon",
  props: {
    icon: String,
    tip: String,
  },
  data() {
    return {
      show: false,
    };
  }
}

export default privacyIcon;
</script>

<style>

</style>